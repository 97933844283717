#page-desktop {
  background-color: #353535 !important;
}

.splash {
  width: 450px !important;
}

.splash img {
  max-width: unset !important;
  max-height: unset !important;
}

@media (max-width: 768px) {
  .splash {
    width: 300px !important;
  }
}
/* .datepicker--button, .datepicker--cell.-current- {
    color: #f0a510;
}

.btn-primary,
.datepicker--cell.-selected-, .datepicker--cell.-current-.-selected- {
	background: #f0a510 !important;
	border: 0px !important;
}
.datepicker--cell.-selected-:hover, .datepicker--cell.-current-.-selected-:hover,
.btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-primary:focus, .open > .dropdown-toggle.btn-primary  {
	background: #f7a502 !important;
    border: 0px !important;
} */